import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Route, Routes } from 'react-router-dom';

import Home from './Views/Home';
import SeperatePdf from './Components/SeperatePdf';

export default function RouteHandler() {
  return (
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/pdf' element={<SeperatePdf />} />
    </Routes>
  );
}
